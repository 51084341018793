<template>
  <div class="loginbox">
	  <canvas id="space" style="position:fixed; z-index: -1; top:0; left:0; width:100%; height:100vh;" width="414" height="896"></canvas>
    <div class="lang" v-if="false">
      <van-dropdown-menu
        :overlay="false"
        active-color="#004ea3"
      >
        <van-dropdown-item
          v-model="lang"
          :options="option"
          @change="Language"
        />
      </van-dropdown-menu>
    </div>
    <div class="loginBody-image">
      <van-image
        width="7rem"
        height="7rem"
        :src="require('../../assets/img/login.png')"
      />
    </div>
    <div class="loginBody">
      <van-row>
        <van-cell-group :border="false">
          <van-field
            v-model="loginform.username"
            clearable
            :placeholder="$t('login.username')"
          >
            <template #left-icon>
              <span class="txtcolor">会员账号：</span>
            </template>
          </van-field>
          <van-field
            v-model="loginform.password"
            :type="type"
            right-icon="closed-eye"
            :placeholder="$t('login.password')"
            @click-right-icon="openeye"
          >
            <template #left-icon>
              <span class="txtcolor">密码：</span>
            </template>
          </van-field>
        </van-cell-group>
      </van-row>
      <div style="padding: 0 2rem;">
		  <van-button
		    :loading="loading"
		    :loading-text="$t('login.loging')"
		    @click="login"
			:disabled="iscanlogin"
		  >{{ $t('login.login') }}</van-button>
		  		<div
		  		  class="kefu"
		  		  @click="toserver"
		  		>
		  		  {{ $t('login.serverurl') }}
		  		</div>
		  		<div
		  		  class="kefu"
		  		>
		  		  在线人数：595988
		  		</div>
		  		<div
		  		  class="kefu"
				  style="font-size: 1.1rem;"
		  		>
		  		  <span class="txtcolor">没有账户</span> <span @click="register">{{$t('login.register')}}</span>
		  		</div>
		  <van-row
		    class="foundPwd"
		  		type="flex"
		  >
		    <van-col
		      span="12"
		      @click="forget"
			  v-if="false"
		    >{{ $t('login.forget') }}</van-col>
		    <van-col
		      span="12"
			  v-if="false"
		      @click="register"
		    >{{
		      $t('login.register')
		    }}</van-col>
		  </van-row>
	  </div>
      <!-- <van-row class="agreement">
        <van-checkbox
          v-model="checked"
          shape="square"
        >{{ $t('login.read') }}
        </van-checkbox>
        <span
          style="color:#004ea3"
          @click="agreement"
        >
          {{ $t('login.agreement') }}
        </span>
      </van-row> -->
    </div>
  </div>
</template>
<script>
	window.requestAnimFrame = function () {
		return window.requestAnimationFrame;
	}();
	
// function setCookie(cname, cvalue, exdays) {
//   var d = new Date()
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
//   var expires = 'expires=' + d.toUTCString()
//   document.cookie = cname + '=' + cvalue + '; ' + expires
// }
// setCookie('googtrans', '/auto/en', 100)
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      option: [
        { text: '中文', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'русский язык', value: 'ru' },
        { text: 'Français', value: 'fr' },
        { text: 'Español', value: 'es' },
        { text: 'Italiano', value: 'it' }
      ],
      loginform: {
        username: '',
        password: ''
      },
      type: 'password',
      loading: false,
      checked: true,
      serverurl: ''
    }
  },
  computed:{
	iscanlogin(){
		return this.loginform.username==''||this.loginform.password==''
	}
  },
  created() {
    function getPar(par) {
      // 获取当前URL
      let localUrl = document.location.href
      // 获取要取得的get参数位置
      let get = localUrl.indexOf(par + '=')
      if (get === -1) {
        return false
      }
      // 截取字符串
      let getPar = localUrl.slice(par.length + get + 1)
      // 判断截取后的字符串是否还有其他get参数
      var nextPar = getPar.indexOf('&')
      if (nextPar !== -1) {
        getPar = getPar.slice(0, nextPar)
      }
      return getPar.slice(0, 2)
    }
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', getPar('lang'))
    }
    this.getserver()
    console.log(this.lang)
    this.getlang('token不能为空', this.lang)
  },
  mounted() {
  	this.initstar();
  },
  methods: {
	  initstar(){
		  var canvas = document.getElementById("space");
		  var c = canvas.getContext("2d");
		  var numStars = 1900;
		  var radius = '0.' + Math.floor(Math.random() * 9) + 1;
		  var focalLength = canvas.width * 2;
		  var warp = 0;
		  var centerX, centerY;
		  var stars = [],
		  	star;
		  var i;
		  var animate = true;
		  initializeStars();
		  function executeFrame() {
		  	if (animate) requestAnimFrame(executeFrame);
		  	moveStars();
		  	drawStars();
		  }
		  function initializeStars() {
		  	centerX = canvas.width / 2;
		  	centerY = canvas.height / 2;
		  	stars = [];
		  	for (i = 0; i < numStars; i++) {
		  		star = {
		  			x: Math.random() * canvas.width,
		  			y: Math.random() * canvas.height,
		  			z: Math.random() * canvas.width,
		  			o: '0.' + Math.floor(Math.random() * 99) + 1
		  		};
		  		stars.push(star);
		  	}
		  }
		  function moveStars() {
		  	for (i = 0; i < numStars; i++) {
		  		star = stars[i];
		  		star.z--;
		  		if (star.z <= 0) {
		  			star.z = canvas.width;
		  		}
		  	}
		  }
		  function drawStars() {
		  	var pixelX, pixelY, pixelRadius;
		  	// Resize to the screen
		  	if (canvas.width != window.innerWidth || canvas.width != window.innerWidth) {
		  		canvas.width = window.innerWidth;
		  		canvas.height = window.innerHeight;
		  		initializeStars();
		  	}
		  	if (warp == 0) {
		  		c.fillStyle = "rgba(26,25,53,1)";
		  		c.fillRect(0, 0, canvas.width, canvas.height);
		  	}
		  	c.fillStyle = "rgba(235, 201, 159, " + radius + ")";
		  	for (i = 0; i < numStars; i++) {
		  		star = stars[i];
		  		pixelX = (star.x - centerX) * (focalLength / star.z);
		  		pixelX += centerX;
		  		pixelY = (star.y - centerY) * (focalLength / star.z);
		  		pixelY += centerY;
		  		pixelRadius = 1 * (focalLength / star.z);
		  		c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
		  		c.fillStyle = "rgba(235, 201, 159, " + star.o + ")";
		  		//c.fill();
		  	}
		  }
		  
		  executeFrame();
	  },
    openeye(event) {
      if (this.type === 'password') {
        event.target.className = 'van-icon van-icon-eye'
        this.type = 'text'
      } else {
        event.target.className = 'van-icon van-icon-closed-eye'
        this.type = 'password'
      }
    },
    // 获取客服链接
    async getserver() {
      const { data } = await this.$http.get('/home/index/serviceurl')
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data
        }
      }
    },
    // 登陆
    async login() {
      if (!this.checked) {
        this.$toast.fail(this.$t('login.readagreement'))
        return false
      }
      if (this.loginform.username === '' || this.loginform.password === '') {
        this.$toast.fail(this.$t('login.complete'))
        return false
      }
      this.loading = true
      const { data } = await this.$http.post(
        '/home/index/login',
        this.loginform
      )
      if (data) {
        if (data.code === 200) {
          console.log(data.data.token)
          this.$toast.success(this.getlang(data.msg, this.lang))
          window.localStorage.setItem('token', data.data.token)
          this.$router.push('/')
        } else {
          this.$toast.fail(this.getlang(data.msg, this.lang))
        }
      }
      this.loading = false
    },
    // 用户协议
    agreement() {
      this.$router.push({ path: '/agreement' })
    },
    // 忘记密码
    forget() {
      this.$router.push({ path: '/forget' })
    },
    // 快速注册
    register() {
      this.$router.push({ path: '/register' })
    },
    Language() {
      let lang = this.lang
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)

      // setCookie('googtrans', '/auto/en', 100)
      // setCookie('googtrans', '', -1111111111111)
      // if (this.$i18n.locale === 'zh') {
      //   this.$i18n.locale = 'en'
      //   localStorage.setItem('lang', 'en')
      //   // setCookie('googtrans', '/auto/en', 100)
      // } else {
      //   this.$i18n.locale = 'zh'
      //   localStorage.setItem('lang', 'zh')
      //   // setCookie('googtrans', '/auto/zh', 100)
      //   // this.$http.get('/home/index/language/zh').then(res => {
      //   //   console.log(res)
      //   // })
      // }
      // window.location.reload()
    },
    // 客服页面
    toserver() {
      window.location.href = this.serverurl
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-field__control{
    color: #cad0e6;
}
/deep/ .van-field__control::placeholder{
    color: gray;
}
/deep/ .van-cell {
    background-color: transparent;
	position: relative;
}
/deep/ .van-cell::after,.van-cell-group::after{
	left: 0;
	content: " ";
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 0.026667rem;
	border-bottom: 0.026667rem solid #e5e5e5;
	color: #e5e5e5;
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
	-webkit-transform: scaleY(0.5);
	-ms-transform: scaleY(0.5);
	transform: scaleY(0.5);
	z-index: 2;
	    border-bottom: 0.026667rem solid #999a9b;
}
.van-cell-group{
	background-color: transparent;
	position: relative;
}

.loginbox {
  height: 100%;
  width: 100%;
  //background: url('../../assets/img/login-bg.png');//登录也背景图
  background-size: cover;
  position: relative;
  .lang {
    position: absolute;
    right: 1.25rem;
    top: 0.8rem;
    color: #ffffff;
    font-size: 0.875rem;
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1rem;
      background: transparent !important;
    }
    .van-dropdown-menu /deep/ .van-dropdown-menu__title {
      color: #ff571e !important;//语言字体
    }
    .van-dropdown-menu /deep/ .van-popup {
      width: 30%;
      left: 68%;
      border-radius: 0.4rem;
      box-shadow: 0 0.13333rem 0.4rem #ff571e;
    }
    .van-dropdown-item__option,
    .van-dropdown-item__option--active {
      margin: 0;
      text-align: center;
    }
    .van-cell__title,
    .van-dropdown-item__option--active .van-dropdown-item__icon {
      color: #ff571e !important;
    }
  }
  .kefu {
	text-align: left;
    font-size: 0.875rem;
    color: #ff571e;
	padding-top: 1.4rem;
  }
  .loginBody-image {
    margin-top: 5rem;
    height: 20%;
    z-index: 1;
  }
  .loginBody {
    width: 100%;
    padding-bottom: 3%;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .van-field {
    padding: 30px 20px 10px 20px;
    font-size: 1rem;

    /deep/.van-cell-group van-hairline--top-bottom,{
    color: #ff571e;
    }
    /deep/.van-field__right-icon,
    /deep/.van-field__left-icon {
      color: #ff571e;
      font-size: 1.2rem;
    }
    .van-icon-clear {
      color: #b3b3b3;
      font-size: 1rem;
    }
    .van-field__control {
      padding-left: 5px;
    }
  }

  .foundPwd {
    font-size: 0.875rem;
    line-height: 3rem;
	  justify-content: space-between;
	  margin-top: 1rem;
    .van-col:first-child {
      text-align: left;
      color: #ff571e;
    }
    .van-col:last-child {
      text-align: right;
      color: #ff571e;
    }
  }
  .van-button {
    margin-top: 1rem;
    width: 100%;
    height: 3rem;
    font-size: 1.23333rem;
    color: #ffffff;
    background-color: #ff571e;
    border-radius: 0.5rem;
    border: none;
  }
  .agreement {
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    /deep/.van-icon {
      height: 1rem;
      width: 1rem;
      font-size: 0.66667rem;
      color: #fff !important;
      border-radius: 4px;
    }
    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }
    .van-checkbox__icon--checked .van-icon {
      color: #fff !important;
      background-color: #ff571e;
      border-color: #ff571e;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
  .iconfont {
    color: #cad0e6;
    font-size: 18px;
  }
}
</style>
